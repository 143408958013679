import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./Components/Layout.jsx";
import LayoutAdmin from "./Components/Layout_Admin.jsx";

//Contexts
import { DarkModeProvider } from "./Context/DarkModeContext";
import { AuthProvider } from "./Context/AuthContext.js";

//Unprotected Pages
import NoPage from "./pages/NoPage.jsx";
import LandingPage from "./pages/Auth/LandingPage.jsx";
import LoginPage from "./pages/Auth/LoginPage.jsx";
import SignUpPage from "./pages/Auth/SignUpPage.jsx";

//STUDENT
import CoursesPage from "./pages/Student/CoursesPage.jsx";

//ADMIN
//STUDENTS SECTION
import AdminStudents from "./pages/Admin/AdminStudents.jsx";
import UserInfoPage from "./pages/Admin/UserInfoPage.jsx";

//COURSES SECTION
import AdminCourses from "./pages/Admin/AdminCourses.jsx";
import AdminCourseContentPage from "./pages/Admin/Content/AdminCourseContentPage.jsx";
import AdminVideos from "./pages/Admin/Content/videos/AdminVideos.jsx";
import AdminMaterials from "./pages/Admin/Content/materials/AdminMaterials.jsx";
import AdminQuizzes from "./pages/Admin/Content/quizzes/AdminQuizzes.jsx";
import AdminAssignments from "./pages/Admin/Content/assignments/AdminAssignments.jsx";

//LIVE REQUEST SECTION
// import AdminLiveRequest from "./pages/Admin/AdminLiveRequest.jsx";

//CODES SECTION
import AdminStore from "./pages/Admin/AdminStore.jsx";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const role = localStorage.getItem("role");

      if (!token || !refreshToken) {
        localStorage.clear();
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(role);
      }
    };

    checkAuth();

    // Add a listener to update authentication status when localStorage changes
    window.addEventListener("storage", checkAuth);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, []);

  if (isAuthenticated === null) return null;

  return (
    <Router>
      <DarkModeProvider>
        <AuthProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />

            {/* Conditional route handling */}
            <Route
              path="/"
              element={
                !isAuthenticated ? (
                  <Navigate to="/landing" />
                ) : isAuthenticated === "User" ? (
                  <Navigate to="/courses" />
                ) : isAuthenticated === "Admin" ? (
                  <Navigate to="/students" />
                ) : null
              }
            />

            {/* Protected routes with Layout for Users */}
            {isAuthenticated === "User" && (
              <Route path="/" element={<Layout />}>
                <Route path="/courses" element={<CoursesPage />} />
              </Route>
            )}

            {/* Protected routes with LayoutAdmin for Admins */}
            {isAuthenticated === "Admin" && (
              <Route path="/" element={<LayoutAdmin />}>
                <Route path="/students" element={<AdminStudents />} />
                <Route
                  path="/students/user/:userId"
                  element={<UserInfoPage />}
                />
                <Route path="/courses" element={<AdminCourses />} />
                <Route
                  path="courses/:courseId"
                  element={<AdminCourseContentPage />}
                />
                <Route
                  path="courses/:courseId/videos"
                  element={<AdminVideos />}
                />
                <Route
                  path="courses/:courseId/materials"
                  element={<AdminMaterials />}
                />
                <Route
                  path="courses/:courseId/quizzes"
                  element={<AdminQuizzes />}
                />
                <Route
                  path="courses/:courseId/assignments"
                  element={<AdminAssignments />}
                />
                {/* <Route path="live-requests" element={<AdminLiveRequest />} /> */}
                <Route path="store" element={<AdminStore />} />
              </Route>
            )}

            {/* Catch-all route */}
            <Route path="*" element={<NoPage />} />
          </Routes>
        </AuthProvider>
      </DarkModeProvider>
    </Router>
  );
}

export default App;
