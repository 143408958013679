import React, { useContext, useEffect, useState } from "react";
import { FaBookOpen, FaClock, FaSun, FaMoon } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineLogout } from "react-icons/ai";
import { Link, Outlet, useLocation } from "react-router-dom";
import { IoStorefront } from "react-icons/io5";
import { PiStudent } from "react-icons/pi";

import logo from "../assets/logo.png";
import darkModeLogo from "../assets/darkMode_logo.png";
import { useAuth } from "../Context/AuthContext";
import { DarkModeContext } from "../Context/DarkModeContext";

function Layout() {
  const { logout } = useAuth();
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(
    localStorage.getItem("activeItem") || ""
  );
  useEffect(() => {
    // Update the active item based on the current path
    let currentActiveItem;
    if (location.pathname === "/students") {
      currentActiveItem = "Students";
    } else if (location.pathname === "/courses") {
      currentActiveItem = "Courses";
    } else if (location.pathname === "/store") {
      currentActiveItem = "Store";
    } else if (location.pathname === "/live-requests") {
      currentActiveItem = "Live Requests";
    }

    if (currentActiveItem) {
      setActiveItem(currentActiveItem);
      localStorage.setItem("activeItem", currentActiveItem); // Save to local storage
    }
  }, [location.pathname]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
  };

  return (
    <div
      className={`flex h-screen ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-black"
      }`}
    >
      {/* Hamburger Menu */}
      {!isSidebarOpen && (
        <nav
          onClick={toggleSidebar}
          className="lg:hidden fixed cursor-pointer top-5 left-0 rounded-r-[8px] bg-customRed text-white p-2 shadow-2xl z-50"
        >
          <div className="mx-2 items-center">
            <RxHamburgerMenu size={30} className="mr-2 " />
          </div>
        </nav>
      )}

      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 h-full w-64 z-50 ${
          isDarkMode
            ? "border-r-2 border-customRed bg-black text-white"
            : "bg-white"
        } text-customBlue flex-shrink-0 transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 lg:fixed`}
      >
        <div className="flex flex-col items-center h-full">
          {/* Logo */}
          <div className="flex justify-center items-center w-full p-6">
            {isDarkMode ? (
              <img
                src={darkModeLogo}
                alt="Logo"
                className="w-42 h-21 object-contain p-3"
              />
            ) : (
              <img src={logo} alt="Logo" className="w-42 h-21 object-contain" />
            )}
          </div>

          {/* Close Sidebar */}
          <button
            className="lg:hidden text-xl absolute top-4 right-4 text-white p-[0.1px] rounded-md bg-customRed"
            onClick={toggleSidebar}
          >
            <IoClose />
          </button>

          {/* Navigation Links */}
          <ul className="text-xl w-full flex-grow">
            <li
              className={`flex cursor-pointer items-center py-5 px-4 hover:shadow-lg transition-shadow duration-300 ${
                activeItem === "Students"
                  ? isDarkMode
                    ? "bg-customRed text-white"
                    : "bg-[#06315B] text-white"
                  : ""
              }`}
            >
              <Link to="/students" className="flex items-center w-full h-full">
                <PiStudent className="mr-4" />
                Students
              </Link>
            </li>
            <li
              className={`flex cursor-pointer items-center py-5 px-4 hover:shadow-lg transition-shadow duration-300 ${
                activeItem === "Courses"
                  ? isDarkMode
                    ? "bg-customRed text-white"
                    : "bg-customBlue text-white"
                  : ""
              }`}
            >
              <Link to="/courses" className="flex items-center w-full h-full">
                <FaBookOpen className="mr-4" />
                Courses
              </Link>
            </li>
            <li
              className={`flex cursor-pointer items-center py-5 px-4 hover:shadow-lg transition-shadow duration-300 ${
                activeItem === "Store"
                  ? isDarkMode
                    ? "bg-customRed text-white"
                    : "bg-customBlue text-white"
                  : ""
              }`}
            >
              <Link to="/store" className="flex items-center w-full h-full">
                <IoStorefront className="mr-4" />
                Store
              </Link>
            </li>
            <li
              className={`flex cursor-pointer items-center py-5 px-4 hover:shadow-lg transition-shadow duration-300 ${
                activeItem === "Live Requests"
                  ? isDarkMode
                    ? "bg-customRed text-white"
                    : "bg-customBlue text-white"
                  : ""
              }`}
            >
              {/* <Link
                to="/live-requests"
                className="flex items-center w-full h-full"
              > */}
              <FaClock className="mr-4" />
              Live Requests
              {/* </Link> */}
            </li>
          </ul>

          {/* Light/Dark Mode Toggle */}

          <div
            className={`flex text-lg items-center justify-between w-full px-6 py-4 ${
              isDarkMode
                ? "border-t border-red-800"
                : "border-t border-grey-600"
            }`}
          >
            <li className="flex items-center gap-2">
              {isDarkMode ? (
                <>
                  <FaSun />
                  Dark Mode
                </>
              ) : (
                <>
                  <FaMoon />
                  Light Mode
                </>
              )}
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  className="sr-only"
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full dark:bg-gray-700 peer-checked:bg-blue-600 peer-focus:ring-4 peer-focus:ring-blue-300 transition duration-300 ease-in-out"></div>
                <div
                  className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transform transition-transform duration-300 ease-in-out ${
                    isDarkMode ? "translate-x-5" : ""
                  }`}
                ></div>
              </label>
            </li>
          </div>

          {/* Logout Button */}
          <div className="w-full p-4">
            <button
              className={`w-full py-3 px-3 ${
                isDarkMode ? "bg-customRed" : "bg-customBlue"
              } text-white rounded-lg flex items-center gap-3 font-bold`}
              onClick={handleLogout}
            >
              <AiOutlineLogout className="text-3xl" />
              Logout
            </button>
          </div>
        </div>
      </aside>

      {/* Main Content */}
      <main
        className={`flex-1 lg:ml-64 ${
          isDarkMode ? "bg-gray-900 text-white" : "bg-gray-100 text-black"
        }`}
      >
        {/* Render child routes/components here */}
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
