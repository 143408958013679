import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdEdit, MdOutlineDelete } from "react-icons/md";
import {
  IoMdAdd,
  IoIosCloseCircleOutline,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { MdDelete } from "react-icons/md";

import "../../../../index.css";
import { DarkModeContext } from "../../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../../Context/axiosInstance.js";

export default function ContentList() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { courseId } = useParams();
  const { state } = location;
  const courseName = state?.courseName;

  const [contents, setContents] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  //states for add modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [newVideos, setNewVideos] = useState([
    { title: "", description: "", url: "" },
  ]);

  // State for editing mode and selected course
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editContentData, setEditContentData] = useState({
    id: "",
    title: "",
    description: "",
    url: "",
  });

  // State for deleting mode and selected course
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);
  const [deleting, setDeleting] = useState(false);

  //Form Data
  const [loadingAddContent, setLoadingAddContent] = useState("");

  const [formErrors, setFormErrors] = useState({
    title: "",
    description: "",
    url: "",
  });
  const [errors, setErrors] = useState([]);

  // New state variables for sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("Title");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axiosInstance.get(
          `/courses/${courseId}/materials?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
            searchQuery
          )}`
        );

        setContents(response.data.response);
        setLoading(false);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      } catch (error) {
        if (error.response) {
          console.error("Response error:", error.response);
          if (error.response.status === 401) {
            // Unauthorized error (token expired or invalid)
          } else {
            setError(
              error.response.data.message || "An unexpected error occurred."
            );
          }
        } else if (error.request) {
          console.error("Request error:", error.request);
          setError("No response received from server.");
        } else {
          console.error("General error:", error.message);
          setError(error.message || "An unexpected error occurred.");
        }
      }
    };
    fetchData();
  }, [courseId, page, pageSize, searchQuery, sortColumn, sortDirection]);

  // Filter courses based on search
  const filteredCourses = contents.filter((content) =>
    content.title
      .trim()
      .toLowerCase()
      .includes(searchQuery.trim().toLowerCase())
  );

  //handle changing of table page not main page
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const openAddModal = () => {
    setAnimationClass("slide-in");
    setIsAddModalOpen(true);
    setError("");
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setFormErrors({});
    setNewVideos([{ title: "", description: "", url: "" }]);
    setFormErrors("");
    setErrors("");
  };

  const handleFormChange = (index, e) => {
    const { name, value } = e.target;
    const updatedVideos = [...newVideos];
    updatedVideos[index] = { ...updatedVideos[index], [name]: value };
    setNewVideos(updatedVideos);
  };

  const handleAddVideo = () => {
    setNewVideos([...newVideos, { title: "", description: "", url: "" }]);
    setError("");
  };

  const handleRemoveVideo = (index) => {
    const updatedVideos = newVideos.filter((_, i) => i !== index);
    setNewVideos(updatedVideos);
    setError("");
  };

  const handlePut = async () => {
    setLoadingAddContent(true);

    const newErrors = {};

    // Validate new materials
    newVideos.forEach((material, index) => {
      if (!material.title || material.title.trim() === "") {
        newErrors[`materials[${index}].title`] = "Title is required.";
      }
      if (!material.description || material.description.trim() === "") {
        newErrors[`materials[${index}].description`] =
          "Description is required.";
      }
      if (!material.url || material.url.trim() === "") {
        newErrors[`materials[${index}].url`] = "URL is required.";
      } else {
        // URL validation using regular expression
        const urlPattern =
          /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
        const trimmedUrl = material.url.trim();
        if (!urlPattern.test(trimmedUrl)) {
          newErrors[`materials[${index}].url`] = "URL is invalid.";
        }
      }
    });

    setFormErrors(newErrors);

    // Prevent sending a request if there is at least one error
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoadingAddContent(false);
      return;
    }

    try {
      // Fetch existing materials
      const existingResponse = await axiosInstance.get(
        `/courses/${courseId}/materials?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );
      const existingVideos = existingResponse.data.response || [];

      // Combine old and new materials
      const combinedVideos = [...existingVideos, ...newVideos];

      console.log(combinedVideos);

      // Construct requestBody with combined materials
      const requestBody = {
        materials: combinedVideos.map((video) => ({
          title: video.title,
          description: video.description,
          url: video.url,
        })),
      };

      console.log(requestBody); // Log to ensure the requestBody is correctly formatted

      // Send the combined list to the server
      const updateResponse = await axiosInstance.put(
        `/courses/${courseId}/materials`,
        requestBody
      );

      console.log(updateResponse.data); // Handle success

      setNewVideos([]); // Clear newVideos after successful update
      setFormErrors({});
      closeAddModal();
      window.location.reload();
    } catch (error) {
      console.error(
        "Error updating materials:",
        error.response?.data.errors[0].reason || error.message
      );
      setErrors([
        error.response?.data.errors[0].reason ||
          "An unexpected error occurred.",
      ]);
      setError(error.response?.data.errors[0].reason);
    } finally {
      setLoadingAddContent(false);
    }
  };

  // Function to close the modal
  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditContentData({
      id: "",
      title: "",
      description: "",
      url: "",
    });
    setFormErrors("");
    setErrors("");
  };

  // This will handle form input changes for editing content
  const handleEditFormChange = (e) => {
    const { id, value } = e.target;
    setEditContentData((prevData) => ({ ...prevData, [id]: value }));
  };

  // Handle the Edit request for updating the content
  const handleEdit = async (e) => {
    e.preventDefault();
    setLoadingAddContent(true);

    const newErrors = {};

    // Validation logic
    if (!editContentData.title) {
      newErrors.title = "Title must not be empty";
    }
    if (!editContentData.description) {
      newErrors.description = "Description must not be empty";
    }
    if (!editContentData.url) {
      newErrors.url = "URL must not be empty";
    } else {
      // URL validation using the URL constructor
      try {
        new URL(editContentData.url);
      } catch (_) {
        newErrors.url = "URL is not valid";
      }
    }

    setFormErrors(newErrors);

    // Prevent sending a request if there is at least one error
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoadingAddContent(false);
      return;
    }

    // Fetch existing materials
    try {
      const existingResponse = await axiosInstance.get(
        `/courses/${courseId}/materials?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );
      const existingVideos = existingResponse.data.response || [];

      // Combine old and new materials
      const updatedVideos = existingVideos.map((video) =>
        video.id === editContentData.id
          ? { ...video, ...editContentData }
          : video
      );

      const requestBody = {
        materials: updatedVideos.map((video) => ({
          title: video.title,
          description: video.description,
          url: video.url,
        })),
      };

      await axiosInstance.put(`/courses/${courseId}/materials`, requestBody);

      // Close modal and reset form
      closeEditModal();
      window.location.reload();
    } catch (error) {
      console.error(
        "Error updating video:",
        error.response?.data.errors[0].reason || error.message
      );
      setErrors([
        error.response?.data.message || "An unexpected error occurred.",
      ]);
    } finally {
      setLoadingAddContent(false);
    }
  };

  //HANDLE DELETE CONTENT
  const handleDelete = async () => {
    if (!selectedContents.length) {
      console.error("No video IDs specified for deletion.");
      return;
    }

    setDeleting(true);

    try {
      // Fetch existing materials
      const response = await axiosInstance.get(
        `/courses/${courseId}/materials?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );
      const existingVideos = response.data.response || [];

      // Filter out the materials to delete
      const updatedVideos = existingVideos.filter(
        (video) => !selectedContents.includes(video.id)
      );

      // Prepare request body
      const requestBody = {
        materials: updatedVideos.map(({ title, description, url }) => ({
          title,
          description,
          url,
        })),
      };

      // Send PUT request to update materials
      await axiosInstance.put(`/courses/${courseId}/materials`, requestBody);

      // On success, clear the selected IDs and close modal
      setConfirmDeleteModal(false);
      setDeleting(false);
      window.location.reload();
    } catch (error) {
      setDeleting(false);
      console.error(
        "Error updating materials:",
        error.response?.data.errors[0].reason || error.message
      );
      setErrors([
        error.response?.data.message || "An unexpected error occurred.",
      ]);
      console.log(errors);
    }
  };

  //this will control selected courses to be able to edit or delete
  const handleSelectCourses = (videoId) => {
    setSelectedContents((prevSelected) =>
      prevSelected.includes(videoId)
        ? prevSelected.filter((id) => id !== videoId)
        : [...prevSelected, videoId]
    );
  };

  // control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Format path to include the section name
  const pathSegments = location.pathname
    .slice(1) // Remove leading slash
    .split("/"); // Split by slashes

  // Format each segment
  const formattedSegments = pathSegments.map(
    (segment) =>
      segment
        .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
  );

  // Insert courseName in the middle of the path
  if (courseName) {
    // Determine the middle index
    const middleIndex = Math.floor(formattedSegments.length / 2);
    formattedSegments.splice(
      middleIndex,
      1,
      courseName.charAt(0).toUpperCase() + courseName.slice(1)
    );
  }

  // Join segments with " > " separator
  const formattedPath = formattedSegments.join(" > ");
  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex w-[58px] gap-1">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>
          </div>

          {/* Display current path */}
          <div
            className={`${
              isDarkMode ? "text-white" : "text-black"
            } flex items-center text-sm capitalize p-2`}
          >
            <span>{formattedPath}</span>
          </div>
        </div>

        {/*/////////////// EDIT MODAL//////////////////////// */}
        {isEditModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-lg shadow-lg w-80`}
            >
              <IoIosCloseCircleOutline
                onClick={closeEditModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />
              <h2 className="text-2xl font-bold text-center mb-4">
                Edit Material
              </h2>
              <form className="flex flex-col gap-4">
                <input
                  type="text"
                  id="title"
                  placeholder="Material Title"
                  value={editContentData.title}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {formErrors.title && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.title}
                  </p>
                )}

                <input
                  type="text"
                  id="description"
                  placeholder="Material Description"
                  value={editContentData.description}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {formErrors.description && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.description}
                  </p>
                )}

                <input
                  type="text"
                  id="url"
                  placeholder="Material URL"
                  value={editContentData.url}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {formErrors.url && (
                  <p className="text-red-500 text-sm mt-1">{formErrors.url}</p>
                )}
              </form>
              <div className="flex justify-center mt-4">
                <button
                  onClick={handleEdit}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 mr-1.5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {loadingAddContent ? "Updating..." : "Update Video"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/*/////////////// ADD MODAL//////////////////////// */}
        {isAddModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} h-[520px] overflow-y-auto relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-100`}
            >
              <IoIosCloseCircleOutline
                onClick={closeAddModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />
              <h2 className="text-2xl font-bold text-center mt-4 mb-4">
                New Material
              </h2>
              <form className="flex flex-col gap-4">
                {newVideos.map((video, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-4 border-b-[1px] border-gray-400 pb-5"
                  >
                    <div className="w-[280px] ">
                      <input
                        type="text"
                        name="title"
                        placeholder="Material Title"
                        value={video.title}
                        onChange={(e) => handleFormChange(index, e)}
                        className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                        required
                      />
                      {formErrors[`materials[${index}].title`] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formErrors[`materials[${index}].title`]}
                        </p>
                      )}

                      <input
                        type="text"
                        name="description"
                        placeholder="Material Description"
                        value={video.description}
                        onChange={(e) => handleFormChange(index, e)}
                        className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                        required
                      />
                      {formErrors[`materials[${index}].description`] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formErrors[`materials[${index}].description`]}
                        </p>
                      )}

                      <input
                        type="text"
                        name="url"
                        placeholder="Material URL"
                        value={video.url}
                        onChange={(e) => handleFormChange(index, e)}
                        className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                        required
                      />
                      {formErrors[`materials[${index}].url`] && (
                        <p className="text-red-500 text-sm mt-1">
                          {formErrors[`materials[${index}].url`]}
                        </p>
                      )}
                      {error && (
                        <p className="text-red-500 text-sm mt-1">{error}</p>
                      )}
                    </div>

                    <div className="flex justify-center mt-2 ">
                      <button
                        type="button"
                        onClick={() => handleRemoveVideo(index)}
                        className="hover:scale-125 ease-in-out duration-300 p-1 items-center justify-center text-red-500 border-2 border-red-500 rounded-full"
                      >
                        <MdDelete size={30} />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="flex justify-center">
                  <button
                    type="button"
                    onClick={handleAddVideo}
                    className="hover:scale-105 ease-in-out duration-300 flex items-center gap-2 mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                  >
                    <IoMdAdd /> Add Material
                  </button>
                </div>
              </form>
              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={handlePut}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 mr-1.5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {loadingAddContent ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* DELETE MODAL */}
        {confirmDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} w-full max-w-md h-auto ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-8 rounded-lg shadow-lg`}
            >
              <div className="flex justify-center pb-4">
                <MdDelete className="text-customRed justify-center" size={50} />
              </div>
              <p className="text-lg font-semibold mb-4 text-center">
                Are you sure you want to delete{" "}
                {selectedContents > 1 ? "this material?" : "these materials?"}
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => handleDelete(selectedContents)}
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  {deleting ? "Deleting..." : "Yes"}
                </button>
                <button
                  onClick={() => setConfirmDeleteModal(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col lg:flex-row lg:justify-between mb-4">
          <div className="flex flex-col lg:flex-row lg:justify-between pb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode ? "bg-[#292929]" : "bg-gray-100"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-2">
            <button
              onClick={openAddModal}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity`}
            >
              <IoMdAdd size={30} className="pr-2" />
              Add
            </button>
            <button
              onClick={() => {
                if (selectedContents.length === 1) {
                  const contentToEdit = contents.find(
                    (content) => content.id === selectedContents[0]
                  );
                  setEditContentData(contentToEdit);
                  setEditModalOpen(true);
                }
              }}
              disabled={selectedContents.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity ${
                selectedContents.length === 0 || selectedContents.length > 1
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
            >
              <MdEdit size={25} className="pr-2" />
              Edit
            </button>
            <button
              onClick={() => {
                setConfirmDeleteModal(true); // Open the modal
              }}
              disabled={selectedContents.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg transition-opacity ${
                selectedContents.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-red-600"
              }`}
            >
              <MdOutlineDelete size={30} className="pr-2" />
              Delete
            </button>
          </div>
        </div>
        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedContents(
                        e.target.checked
                          ? filteredCourses.map((video) => video.id)
                          : []
                      )
                    }
                    checked={selectedContents.length === filteredCourses.length}
                  />
                </th>
                <th
                  onClick={() => toggleSortDirection("Title")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                  {sortColumn === "Title" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Description")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                  {sortColumn === "Description" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Url")}
                  className="cursor-pointer justify-center flex items-center px-4 py-2 text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Link
                  {sortColumn === "Url" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {filteredCourses.length > 0 &&
                filteredCourses.map((content) => (
                  <tr key={content.id}>
                    <td className="px-4 py-2 whitespace-nowrap font-medium">
                      <input
                        type="checkbox"
                        checked={selectedContents.includes(content.id)}
                        onChange={() => handleSelectCourses(content.id)}
                      />
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center">
                      <h2 className="text-[16px]">{content.title}</h2>
                    </td>
                    <td className="px-4 py-2 text-base break-words text-center">
                      {content.description}
                    </td>
                    <td className="px-4 py-2 break-words text-base text-[#2083D8] text-center">
                      {content.url}
                    </td>
                  </tr>
                ))}
              {!contents.length && !loading && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg text-gray-500`}
                  >
                    No Materials Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {loading && (
          <p
            className={`${
              isDarkMode ? "text-white" : "text-gray-500"
            } px-4 py-5 text-center text-lg text-gray-500`}
          >
            Loading...
          </p>
        )}
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Items per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[10, 20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
