import React, { useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CiExport } from "react-icons/ci";
// import { FaLock, FaLockOpen } from "react-icons/fa";
import { DarkModeContext } from "../../Context/DarkModeContext.jsx";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Context/axiosInstance.js";

export default function UserList() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // New state variables for sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("RegistrationDate");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axiosInstance.get(
          `/users?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
            searchQuery
          )}`
        );

        setUsers(response.data.response);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Unauthorized error (token expired or invalid)
          // The Axios interceptor will handle the token refresh and retry.
        } else {
          setError(error.message || "An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [page, pageSize, searchQuery, sortColumn, sortDirection]);

  // Filter users based on search query
  const filteredUsers = users.filter((user) =>
    user.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleExportCSV = async () => {
    try {
      setExporting(true);
      const response = await axiosInstance.get("/users?page=1&pageSize=10000");

      // Ensure that the response contains the users data
      if (!response.data) {
        alert("No users data found");
      }

      // Convert JSON data to worksheet
      const ws = XLSX.utils.json_to_sheet(response.data.response);

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Users");

      // Write workbook to CSV format
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      // Create a Blob from the CSV data and save it
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      setExporting(false);
      saveAs(blob, "Registered_Students.xlsx");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  const handleUserInfoPage = (userId) => {
    if (userId) {
      navigate(`/students/user/${userId}`);
    } else {
      console.error("User ID is undefined");
    }
  };

  // Toggle sort direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        <div className="flex flex-col lg:flex-row lg:justify-between mb-4">
          <div className="flex flex-col lg:flex-row lg:justify-start pb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode ? "bg-[#292929]" : "bg-gray-100"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleExportCSV}
              className="flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 justify-center lg:py-3 bg-blue-500 text-white rounded-lg transition-transform transform hover:scale-customScale"
            >
              <CiExport
                size={24}
                className={`${exporting ? "hidden" : "pr-2"}`}
              />
              {exporting ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path d="M4 12a8 8 0 018-8v8H4z" fill="currentColor" />
                  </svg>
                  <span className="ml-2">EXPORTING...</span>{" "}
                </>
              ) : (
                "Export CSV"
              )}
            </button>
          </div>
          {/* <div className="flex flex-col lg:flex-row lg:gap-2">
            <button
              disabled={selectedUsers.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg transition-opacity ${
                selectedUsers.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-red-600"
              }`}
            >
              <FaLock size={20} className="pr-2" />
              Lock
            </button>
            <button
              disabled={selectedUsers.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-green-500 text-white rounded-lg transition-opacity ${
                selectedUsers.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-green-600"
              }`}
            >
              <FaLockOpen size={20} className="pr-2" />
              Unlock
            </button>
          </div> */}
        </div>
        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedUsers(
                        e.target.checked
                          ? filteredUsers.map((user) => user.id)
                          : []
                      )
                    }
                    checked={selectedUsers.length === filteredUsers.length}
                  />
                </th>
                <th
                  onClick={() => toggleSortDirection("Name")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                  {sortColumn === "Name" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("GradeLevel")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Grade Level
                  {sortColumn === "GradeLevel" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>

                <th
                  onClick={() => toggleSortDirection("PhoneNumber")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Phone Number
                  {sortColumn === "PhoneNumber" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("RegistrationDate")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Registration Date
                  {sortColumn === "RegistrationDate" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Email")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Email
                  {sortColumn === "Email" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {filteredUsers.length > 0 &&
                filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="px-4 py-2 whitespace-nowrap font-medium ">
                      <input
                        type="checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleSelectUser(user.id)}
                      />
                    </td>
                    <td
                      onClick={() => handleUserInfoPage(user.id)}
                      className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center"
                    >
                      <h2 className="text-[16px]">{user.name}</h2>
                      <p className="text-[12px]">{user.id}</p>
                    </td>
                    <td
                      onClick={() => handleUserInfoPage(user.id)}
                      className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center"
                    >
                      {user.gradeLevel}
                    </td>
                    <td
                      onClick={() => handleUserInfoPage(user.id)}
                      className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]"
                    >
                      {user.phoneNumber}
                    </td>
                    <td
                      onClick={() => handleUserInfoPage(user.id)}
                      className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]"
                    >
                      {new Date(user.registrationDate).toLocaleString("en-EG", {
                        timeZone: "Africa/Cairo",
                      })}
                    </td>
                    <td
                      onClick={() => handleUserInfoPage(user.id)}
                      className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center"
                    >
                      {user.email}
                    </td>
                  </tr>
                ))}
              {!users.length && !loading && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-4 py-5 text-center text-lg text-gray-500`}
                  >
                    No users found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {loading && (
          <p
            className={`${
              isDarkMode ? "text-white" : "text-gray-500"
            } px-4 py-5 text-center text-lg text-gray-500`}
          >
            Loading...
          </p>
        )}
        {error && (
          <p className="mt-4 text-lg text-red-600 justify-center">
            {error.message}
          </p>
        )}
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Items per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[10, 20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
