import axios from "axios";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
  withCredentials: true,
});

// Request interceptor to include the access token
axiosInstance.interceptors.request.use(
  (config) => {
    const encryptedAccessToken = localStorage.getItem("accessToken");
    if (encryptedAccessToken) {
      const accessToken = decryptData(encryptedAccessToken);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token refresh
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if the error is related to authentication (401)
    if (error.response && error.response.status === 401) {
      try {
        const encryptedRefreshToken = localStorage.getItem("refreshToken");
        const encryptedAccessToken = localStorage.getItem("accessToken");
        const encryptedUserId = localStorage.getItem("userId");

        // Check if any token or user ID is missing
        if (
          !encryptedRefreshToken ||
          !encryptedAccessToken ||
          !encryptedUserId
        ) {
          // Handle missing tokens or user ID
          localStorage.clear();
          window.location.href = "/login";
          return Promise.reject(new Error("Incomplete authentication data"));
        }

        // Decrypt tokens
        const refreshToken = decryptData(encryptedRefreshToken);
        const accessToken = decryptData(encryptedAccessToken);
        const userId = decryptData(encryptedUserId);

        // console.log("Attempting to refresh token...");
        // console.log("UserId:", userId);
        // console.log("Old Refresh Token:", refreshToken);

        const response = await axiosInstance.post(
          "/authentication/refresh-access-token",
          {
            userId,
            accessToken,
            refreshToken,
          }
        );

        const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
          response.data;

        // console.log("New Refresh Token:", newRefreshToken);
        // console.log("New Access Token:", newAccessToken);

        // Encrypt new tokens
        const encryptedNewAccessToken = encryptData(newAccessToken);
        const encryptedNewRefreshToken = encryptData(newRefreshToken);

        // Update localStorage with new tokens
        localStorage.setItem("accessToken", encryptedNewAccessToken);
        localStorage.setItem("refreshToken", encryptedNewRefreshToken);

        // Retry the original request with the new access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
