import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaMale, FaFemale } from "react-icons/fa";
import { BiSolidMessageAltError } from "react-icons/bi";

import { DarkModeContext } from "../../Context/DarkModeContext";
import axiosInstance from "../../Context/axiosInstance";
import "../../index.css";

function UserInfoPage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get(`/users/${userId}`);
        setUser(response.data);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError(new Error("User not found."));
        } else {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  if (loading) {
    return (
      <p className="flex justify-center items-center text-lg font-semibold">
        Loading...
      </p>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
        <div className="bg-red-100 p-4 w-full max-w-[400px] rounded-lg text-center relative">
          <div className="absolute -top-6 left-1/2 transform -translate-x-1/2">
            <BiSolidMessageAltError
              size={50}
              className="text-red-600 mt-[-30px]"
            />
          </div>
          <p className="text-lg font-semibold text-red-600">
            We encountered an issue while fetching user data. Refresh the page
            to try again.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${
        isDarkMode ? "bg-[#292929] text-white" : "bg-gray-50 text-black"
      } min-h-screen p-[30px] md:px-[160px] lg:pt-12`}
    >
      <div
        className={` flex flex-col lg:flex-row gap-4 max-w-screen-lg mx-auto`}
      >
        {/* User Info Card */}
        <div
          className={`${
            isDarkMode
              ? "bg-[#1e1e1e] border-2 border-customRed text-white"
              : "bg-white text-black"
          } rounded-lg shadow-lg flex-1`}
        >
          {/* Image on Top */}
          <div className="relative p-3">
            {user.gender === "Male" ? (
              <FaMale className="w-full h-32 pt-4" />
            ) : (
              <FaFemale className="w-full h-32 pt-4" />
            )}
          </div>

          {/* Information Below Image */}
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-4 text-center underline">
              User Information
            </h1>
            {user && (
              <div className="space-y-4">
                {Object.entries({
                  ID: user.id,
                  Email: user.email,
                  Name: user.name,
                  Gender: user.gender,
                  "Guardian Phone Number": user.guardianPhoneNumber,
                  "Phone Number": user.phoneNumber,
                  Address: user.address,
                  "Academic Level": user.academicLevel,
                  School: user.school,
                  Governorate: user.governorate,
                  Area: user.area,
                }).map(([key, value]) => (
                  <p className="text-lg" key={key}>
                    <strong className="font-semibold">{key}:</strong> {value}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Lorem Ipsum Card */}
        <div
          className={`${
            isDarkMode
              ? "bg-[#1e1e1e] border-2 border-customRed text-white"
              : "bg-white text-black"
          } flex flex-col rounded-lg shadow-md p-6 flex-1`}
        >
          <h2 className="text-2xl font-semibold text-center mb-4 underline">
            Registered Courses
          </h2>
          <div className="flex flex-1 items-center justify-center">
            <p className="text-[28px] leading-relaxed animate-pulse">
              Coming Soon
            </p>
          </div>
        </div>
      </div>

      {/* Back Button */}
      <div className="mt-4 text-center">
        <button
          className={`${
            isDarkMode
              ? "text-lg px-12 py-3 text-center me-2 lg:me-10 mb-2 text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg"
              : "text-lg px-12 py-3 text-center me-2 lg:me-10 mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          }`}
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default UserInfoPage;
