import React from "react";
import "./CoursesPage.css";

function CoursesPage() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="relative flex flex-col items-center justify-center p-4 bg-white text-black rounded-lg shadow-lg">
        <div className="animation-container">
          <div className="animation">
            <div className="circle"></div>
          </div>
          <div className="text">Coming Soon</div>
        </div>
      </div>
    </div>
  );
}

export default CoursesPage;
