import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdEdit, MdOutlineDelete, MdDelete } from "react-icons/md";
import { IoMdAdd, IoIosCloseCircleOutline } from "react-icons/io";

import "../../index.css";
import { DarkModeContext } from "../../Context/DarkModeContext.jsx";
import axiosInstance from "../../Context/axiosInstance.js";

export default function CoursesList() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  //states for course options modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [apiErrors, setApiErrors] = useState(null);

  //for edit
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editCourseData, setEditCourseData] = useState(null);

  // State for deleting mode and selected course
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);
  const [deleting, setDeleting] = useState(false);

  //Form Data
  const [loadingaddCourse, setLoadingaddCourse] = useState("");
  const [loadingAddImage, setLoadingAddImage] = useState("");
  const [formData, setFormData] = useState({
    courseName: "",
    courseGrade: "",
    coursePrice: "",
    courseImageUrl: "",
    courseImageFile: null, // Store file object for preview
  });
  const [formErrors, setFormErrors] = useState({
    courseName: "",
    courseGrade: "",
    coursePrice: "",
    courseImageUrl: "",
    courseImageFile: null,
  });
  const [editFormErrors, setEditFormErrors] = useState({
    courseName: "",
    courseGrade: "",
    coursePrice: "",
    courseImageUrl: "",
    courseImageFile: null,
  });

  // New state variables for sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("Name");
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axiosInstance.get(
          `/courses?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
            searchQuery
          )}`
        );

        setCourses(response.data.response);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Unauthorized error (token expired or invalid)
          // The Axios interceptor will handle the token refresh and retry.
        } else {
          setError(error.message || "An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [page, pageSize, searchQuery, sortColumn, sortDirection]);

  // Filter courses based on search
  const filteredCourses = courses.filter((course) =>
    course.name.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  //handle changing of table page not main page
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleAddCourseModalOpen = () => {
    setModalOpen(true);
    setAnimationClass("slide-in");
  };

  const handleFormChange = async (e) => {
    const { id, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        try {
          // Upload the image and get the URL
          const imageUrl = await uploadImage(file);
          // Update formData with the image URL and file for preview
          setFormData((prevData) => ({
            ...prevData,
            courseImageUrl: imageUrl,
            courseImageFile: file, // Store file object for preview
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            courseImageUrl: "",
          }));
        } catch (error) {
          console.error("Image upload failed:", error.message);
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            courseImageUrl: "Image upload failed", // Fixed key
          }));
        }
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const uploadImage = async (imageFile) => {
    try {
      setLoadingAddImage(true);
      const formDataForImage = new FormData();
      formDataForImage.append("file", imageFile);

      console.log("Uploading file:", formDataForImage.get("file")); // Log the file being sent

      const response = await axiosInstance.post(
        "/attachments/upload-image",
        formDataForImage,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoadingAddImage(false);

      const imageUrl = response.data; // Ensure this is correct
      console.log("Image uploaded successfully, URL:", imageUrl);

      setApiErrors("");
      return imageUrl;
    } catch (error) {
      setLoadingAddImage(false);
      console.error(
        "Image Upload Error:",
        error.response ? error.response.data.errors[0].reason : error.message
      );
      setApiErrors(error.response.data.errors[0].reason);
      throw error; // Re-throw error to handle it in the caller
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setLoadingaddCourse(true);

    const newErrors = {};

    // Validate course name
    if (!formData.courseName) {
      newErrors.courseName = "Course Name must not be empty";
    }

    // Validate course grade
    if (!formData.courseGrade) {
      newErrors.courseGrade = "Grade must not be empty";
    }

    // Validate course price
    if (!formData.coursePrice) {
      newErrors.coursePrice = "Price must not be empty";
    } else if (!/^\d+(\.\d+)?$/.test(formData.coursePrice)) {
      newErrors.coursePrice = "Price must be a valid number";
    } else if (formData.coursePrice <= 0) {
      newErrors.coursePrice = "Price must be greater than 0";
    }

    // Validate course image URL
    if (!formData.courseImageUrl || !formData.courseImageFile) {
      newErrors.courseImageUrl = "Image must not be empty";
    } else {
      const allowedTypes = ["image/png", "image/jpeg"];
      if (
        !allowedTypes.includes(
          formData.courseImageUrl.type || formData.courseImageFile.type
        )
      ) {
        newErrors.courseImageUrl = "Image must be a PNG or JPEG file";
      }
    }

    // Set form errors
    setFormErrors(newErrors);

    // If there are validation errors, stop the function
    if (Object.keys(newErrors).length > 0) {
      setLoadingaddCourse(false);
      return;
    }

    try {
      const response = await axiosInstance.post("/courses", {
        name: formData.courseName.trim(),
        gradeLevel: formData.courseGrade,
        price: parseFloat(formData.coursePrice),
        imageUrl: formData.courseImageUrl,
      });

      console.log("Course added successfully:", response.data);
      setModalOpen(false);
      window.location.reload();
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.errors
          ? error.response.data.errors.map((err) => err.reason).join(", ")
          : error.message;

      console.error("Error adding course:", errorMessage);
    } finally {
      setLoadingaddCourse(false);
    }
  };

  const handleEdit = async () => {
    if (!editCourseData) return;

    setLoadingaddCourse(true);

    const newEditErrors = {};

    // Validate course name
    if (!editCourseData.name) {
      newEditErrors.courseName = "Course Name must not be empty";
    }

    // Validate course grade
    if (!editCourseData.gradeLevel) {
      newEditErrors.courseGrade = "Grade must not be empty";
    }

    // Validate course price
    if (!editCourseData.price) {
      newEditErrors.coursePrice = "Price must not be empty";
    } else if (!/^\d+(\.\d+)?$/.test(editCourseData.price)) {
      newEditErrors.coursePrice = "Price must be a valid number";
    } else if (editCourseData.price <= 0) {
      newEditErrors.coursePrice = "Price must be greater than 0";
    }

    // Set form errors
    setEditFormErrors(newEditErrors);

    // If there are validation errors, stop the function
    if (Object.keys(newEditErrors).length > 0) {
      setLoadingaddCourse(false);
      return;
    }

    try {
      let imageUrl = editCourseData.imageUrl;

      // Check if an image needs to be uploaded
      if (editCourseData.image) {
        // Upload image and get the new URL
        imageUrl = await uploadImage(editCourseData.image);
      }

      // Edit course with updated data
      await axiosInstance.put(`/courses/${editCourseData.id}`, {
        name: editCourseData.name,
        gradeLevel: editCourseData.gradeLevel,
        price: editCourseData.price,
        imageUrl,
      });

      // Update course in the list
      setCourses((prevCourses) =>
        prevCourses.map((course) =>
          course.id === editCourseData.id
            ? {
                ...course,
                name: editCourseData.name,
                gradeLevel: editCourseData.gradeLevel,
                price: editCourseData.price,
                imageUrl,
              }
            : course
        )
      );

      // Close the edit modal
      setEditModalOpen(false);
    } catch (error) {
      console.error(
        "Error editing course:",
        error.response ? error.response.data.errors[0].reason : error.message
      );
      setError(
        error.response ? error.response.data.errors[0].reason : error.message
      );
    } finally {
      setLoadingaddCourse(false);
    }
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setFormErrors({});
    setFormData({});
    setError();
    setApiErrors("");
    setEditFormErrors({
      courseName: "",
      courseGrade: "",
      coursePrice: "",
      courseImageUrl: "",
    });
  };

  const handleCloseAddModal = () => {
    setModalOpen(false);
    setFormErrors({});
    setFormData({
      courseName: "",
      courseGrade: "",
      coursePrice: "",
      courseImageUrl: "",
    });
    setError();
    setApiErrors("");
    setEditFormErrors({
      courseName: "",
      courseGrade: "",
      coursePrice: "",
      courseImageUrl: "",
    });
  };

  //HANDLE DELETE CONTENT
  const handleDelete = async () => {
    if (selectedCourses.length === 0) {
      alert("No courses selected for deletion.");
      return;
    }

    setDeleting(true);

    try {
      // Prepare DELETE requests
      const deleteRequests = selectedCourses.map((courseId) =>
        axiosInstance.delete(`/courses/${courseId}`)
      );

      // Perform all DELETE requests concurrently
      await Promise.all(deleteRequests);

      // Clear selected courses and update UI
      setSelectedCourses([]);
      setConfirmDeleteModal(false);

      // Success message
      window.location.reload();
    } catch (error) {
      // Error handling
      setDeleting(false);
      console.error("Error deleting courses:", error);
    }
  };

  //this will control page of links. inshallah
  const handleCoursePage = (id, name) => {
    if (id) {
      navigate(`/courses/${id}`, { state: { name } });
    } else {
      console.error("Course ID is undefined");
    }
  };

  //this will control selected courses to be able to edit or delete
  const handleSelectCourses = (courseId) => {
    setSelectedCourses((prevSelected) =>
      prevSelected.includes(courseId)
        ? prevSelected.filter((id) => id !== courseId)
        : [...prevSelected, courseId]
    );
  };

  // control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/*/////////////// EDIT COURSE MODAL//////////////////////// */}
        {isEditModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-80`}
            >
              {/* Close icon */}
              <IoIosCloseCircleOutline
                onClick={handleCloseEditModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />

              {/* Edit Form */}
              <h2 className="text-2xl font-bold text-center mt-4 mb-4">
                Edit Course
              </h2>
              <form className="flex flex-col gap-4">
                {/* Image Upload Input */}
                <div className="mt-4 p-4">
                  <label
                    htmlFor="editCourseImage"
                    className="block cursor-pointer"
                  >
                    <div className="w-300px h-300px border border-dashed border-gray-400 flex items-center justify-center">
                      {editCourseData?.imageUrl ? (
                        <img
                          src={editCourseData.imageUrl}
                          alt="Course Preview"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <span className="text-gray-500">Upload Image</span>
                      )}
                    </div>
                    {/* Display error message */}
                    {editFormErrors.courseImageUrl && (
                      <p className="text-red-500 text-sm mt-1">
                        {editFormErrors.courseImageUrl}
                      </p>
                    )}
                    {apiErrors && (
                      <p className="text-red-500 text-sm mt-2 text-center">
                        {apiErrors}
                      </p>
                    )}

                    <input
                      type="file"
                      id="editCourseImage"
                      name="editCourseImage"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          setEditCourseData((prevData) => ({
                            ...prevData,
                            image: file,
                            imageUrl: URL.createObjectURL(file),
                          }));
                        }
                      }}
                      className="hidden"
                    />
                  </label>
                </div>

                {/* Course Name */}
                <div className="relative">
                  <input
                    type="text"
                    id="editCourseName"
                    placeholder=" "
                    value={editCourseData?.name || ""}
                    onChange={(e) =>
                      setEditCourseData((prevData) => ({
                        ...prevData,
                        name: e.target.value,
                      }))
                    }
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="editCourseName" className="floating-label">
                    Course's Name
                  </label>
                  {editFormErrors.courseName && (
                    <p className="text-red-500 text-sm mt-1">
                      {editFormErrors.courseName}
                    </p>
                  )}
                </div>

                {/* Grade Level */}
                <select
                  id="editCourseGrade"
                  value={editCourseData?.gradeLevel || ""}
                  onChange={(e) =>
                    setEditCourseData((prevData) => ({
                      ...prevData,
                      gradeLevel: e.target.value,
                    }))
                  }
                  className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                >
                  <option value="" disabled>
                    Select Grade Level
                  </option>
                  <option value="Secondary School - Second Year">
                    Secondary School - Second Year
                  </option>
                  <option value="Secondary School - Third Year Literary">
                    Secondary School - Third Year Literary
                  </option>
                  <option value="Secondary School - Third Year Scientific">
                    Secondary School - Third Year Scientific
                  </option>
                  <option value="Faculty of Engineering">
                    Faculty of Engineering
                  </option>
                  <option value="Faculty of Computer Science">
                    Faculty of Computer Science
                  </option>
                  <option value="Faculty of Science">Faculty of Science</option>
                </select>
                {editFormErrors.courseGrade && (
                  <p className="text-red-500 text-sm mt-1">
                    {editFormErrors.courseGrade}
                  </p>
                )}

                {/* Course Price */}
                <div className="relative">
                  <input
                    type="text"
                    id="editCoursePrice"
                    placeholder=" "
                    value={editCourseData?.price || ""}
                    onChange={(e) =>
                      setEditCourseData((prevData) => ({
                        ...prevData,
                        price: e.target.value,
                      }))
                    }
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="editCoursePrice" className="floating-label">
                    Course's Price
                  </label>
                  {editFormErrors.coursePrice && (
                    <p className="text-red-500 text-sm mt-1">
                      {editFormErrors.coursePrice}
                    </p>
                  )}
                </div>
              </form>

              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={() => handleEdit()}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 mr-1.5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {loadingaddCourse ? "Editing..." : "Edit Course"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/*/////////////// ADD COURSE MODAL//////////////////////// */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-80`}
            >
              {/* Icon container */}
              <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center">
                <div className="bg-white w-[80px] h-[60px] shadow-md rounded-full border border-gray-300 flex justify-center items-center">
                  <IoMdAdd size={40} className="text-[#2264E5] animate-pulse" />
                </div>
              </div>
              {/* Close icon */}
              <IoIosCloseCircleOutline
                onClick={handleCloseAddModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />

              {/* Add Form */}
              <h2 className="text-2xl font-bold text-center mt-4 mb-4">
                New Course
              </h2>
              <form className="flex flex-col gap-4">
                {/* Image Upload Input */}
                <div className="mt-4 p-4">
                  <label
                    htmlFor="courseImageUrl"
                    className="block cursor-pointer"
                  >
                    <div className="w-300px h-300px border border-dashed border-gray-400 flex items-center justify-center">
                      {formData.courseImageFile ? (
                        <img
                          src={URL.createObjectURL(formData.courseImageFile)}
                          alt="Preview"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : formData.courseImageUrl ? (
                        <img
                          src={formData.courseImageUrl}
                          alt="Preview"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <span
                          className={`text-gray-500 ${
                            loadingAddImage && "animate-pulse"
                          }`}
                        >
                          {loadingAddImage ? "Uploading..." : "Upload Image"}
                        </span>
                      )}
                    </div>

                    {/* Display error message */}
                    {formErrors.courseImageUrl && (
                      <p className="text-red-500 text-sm mt-2 text-center">
                        {formErrors.courseImageUrl}
                      </p>
                    )}
                    {apiErrors && (
                      <p className="text-red-500 text-sm mt-2 text-center">
                        {apiErrors}
                      </p>
                    )}
                    <input
                      type="file"
                      id="courseImageUrl"
                      name="courseImageUrl"
                      accept="image/*"
                      onChange={handleFormChange}
                      className="hidden"
                    />
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    id="courseName"
                    placeholder=" "
                    value={formData.courseName}
                    onChange={handleFormChange}
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="courseName" className="floating-label">
                    Course's Name
                  </label>
                  {formErrors.courseName && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.courseName}
                    </p>
                  )}
                </div>

                {/* Grade Level Input */}
                <select
                  id="courseGrade"
                  value={formData.courseGrade}
                  onChange={handleFormChange}
                  className="px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                >
                  <option value="" disabled>
                    Select Grade Level
                  </option>
                  <option value="Secondary School - Second Year">
                    Secondary School - Second Year
                  </option>
                  <option value="Secondary School - Third Year Literary">
                    Secondary School - Third Year Literary
                  </option>
                  <option value="Secondary School - Third Year Scientific">
                    Secondary School - Third Year Scientific
                  </option>
                  <option value="Faculty of Engineering">
                    Faculty of Engineering
                  </option>
                  <option value="Faculty of Computer Science">
                    Faculty of Computer Science
                  </option>
                  <option value="Faculty of Science">Faculty of Science</option>
                </select>
                {formErrors.courseGrade && (
                  <p className="text-red-500 text-sm mt-1">
                    {formErrors.courseGrade}
                  </p>
                )}

                <div className="relative">
                  <input
                    type="text"
                    id="coursePrice"
                    placeholder=" "
                    value={formData.coursePrice}
                    onChange={handleFormChange}
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="courseName" className="floating-label">
                    Course's Price
                  </label>
                  {formErrors.coursePrice && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.coursePrice}
                    </p>
                  )}
                </div>
              </form>
              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={handleAdd}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 mr-1.5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {loadingaddCourse ? "Adding..." : "Add Course"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* DELETE MODAL */}
        {confirmDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} w-full max-w-md h-auto ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-8 rounded-lg shadow-lg`}
            >
              <div className="flex justify-center pb-4">
                <MdDelete className="text-customRed justify-center" size={50} />
              </div>
              <p className="text-lg font-semibold mb-4 text-center">
                Are you sure you want to delete{" "}
                {selectedCourses > 1 ? "this video?" : "these videos?"}
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => handleDelete(selectedCourses)}
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  {deleting ? "Deleting..." : "Yes"}
                </button>
                <button
                  onClick={() => setConfirmDeleteModal(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col lg:flex-row lg:justify-between mb-4">
          <div className="flex flex-col lg:flex-row lg:justify-between pb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode ? "bg-[#292929]" : "bg-gray-100"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-2">
            <button
              onClick={handleAddCourseModalOpen}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity`}
            >
              <IoMdAdd size={30} className="pr-2" />
              Add Course
            </button>
            <button
              disabled={selectedCourses.length !== 1}
              onClick={() => {
                if (selectedCourses.length === 1) {
                  const courseToEdit = courses.find(
                    (course) => course.id === selectedCourses[0]
                  );
                  setEditCourseData(courseToEdit);
                  setEditModalOpen(true);
                }
              }}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity ${
                selectedCourses.length !== 1
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
            >
              <MdEdit size={25} className="pr-2" />
              Edit Course
            </button>

            <button
              onClick={() => {
                setConfirmDeleteModal(true);
              }}
              disabled={selectedCourses.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg transition-opacity ${
                selectedCourses.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-red-600"
              }`}
            >
              <MdOutlineDelete size={30} className="pr-2" />
              Delete Course
            </button>
          </div>
        </div>
        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedCourses(
                        e.target.checked
                          ? filteredCourses.map((course) => course.id)
                          : []
                      )
                    }
                    checked={selectedCourses.length === filteredCourses.length}
                  />
                </th>
                <th className=" px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"></th>
                <th
                  onClick={() => toggleSortDirection("Name")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Name
                  {sortColumn === "Name" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("GradeLevel")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Grade Level
                  {sortColumn === "GradeLevel" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Price")}
                  className="cursor-pointer items-center px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                  {sortColumn === "Price" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {filteredCourses.length > 0 &&
                filteredCourses.map((course) => (
                  <tr key={course.id}>
                    <td className="px-4 py-2 whitespace-nowrap font-medium">
                      <input
                        className="cursor-pointer"
                        type="checkbox"
                        checked={selectedCourses.includes(course.id)}
                        onChange={() => handleSelectCourses(course.id)}
                      />
                    </td>
                    <td
                      onClick={() => handleCoursePage(course.id, course.name)}
                      className="cursor-pointer flex justify-center items-center gap-3 px-4 py-2 whitespace-nowrap text-base"
                    >
                      <img
                        className="w-[70px] max-w-[100px] h-[50px] object-cover rounded-[5px]"
                        src={course.imageUrl}
                        alt=""
                      />
                    </td>

                    <td
                      onClick={() => handleCoursePage(course.id, course.name)}
                      className="cursor-pointer items-center gap-3 px-4 py-2 whitespace-nowrap text-base justify-center"
                    >
                      <h2 className="text-[16px] text-center">{course.name}</h2>
                    </td>
                    <td
                      onClick={() => handleCoursePage(course.id, course.name)}
                      className="px-4 py-2 whitespace-nowrap text-base text-center"
                    >
                      {course.gradeLevel}
                    </td>
                    <td
                      onClick={() => handleCoursePage(course.id, course.name)}
                      className="px-4 py-2 whitespace-nowrap text-base text-[#2083D8] text-center"
                    >
                      {course.price} EGP
                    </td>
                  </tr>
                ))}
              {!courses.length && !loading && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg text-gray-500`}
                  >
                    No Courses Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {loading && (
          <p
            className={`${
              isDarkMode ? "text-white" : "text-gray-500"
            } px-4 py-5 text-center text-lg text-gray-500`}
          >
            Loading...
          </p>
        )}
        {error && <p className="mt-4 text-lg text-red-600">{error.message}</p>}
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Courses per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[10, 20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
